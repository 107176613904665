/* MAIN */
.main-container {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.main-container .nyliabutton-container .btn {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0;
}

.main-container .nyliabutton-container .btn:hover {
  color: black;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

/* ACCORDION SETTINGS */
.accordion-flush .accordion-item {
  background-color: transparent;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  background-color: transparent;
}

.accordion-body {
  color: white;
}

.accordion {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgb(137, 201, 137)'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* ADD AS ID FOR HIGHLIGHTING TITLE */
#highlightTitle {
  color: rgb(137, 201, 137);
  font-size: 0.8rem;
  font-weight: bolder;
}

/* TWO TYPES OF MAIN SECTIONS (blk bkg, img bkg) */
.main-bkg,
.main-img-bkg {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-position: center;
}

.main-bkg {
  background-color: rgba(0, 0, 0, 0.85);
}

.main-bkg .main-box {
  background-color: transparent;
}

.main-bkg .main-box p,
.main-img-bkg .main-box p {
  text-align: left;
}

.main-box {
  /* width: 100%; */
  /* background-color: rgb(0, 0, 0, 0.7); */
  /* margin-right: 2.5rem; */
  /* margin-left: 2.5rem; */
  padding: 1.3rem;
  margin-bottom: 2rem;
  /* max-width: 85%; */
}

.main-bkg .main-box-centered p,
.main-img-bkg .main-box-centered p {
  text-align: center;
  vertical-align: middle;
}

h1 {
  font-family: "Michroma", sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.1vw;
}

@media all and (orientation: portrait) {
  #main-img-bkg background-position {
    /* background-repeat: no-repeat;
      background-size: cover;
      position: relative; */
    -o-background-size: auto 100%;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    background-position: right top;
    background-size: contain;
  }
}

@media all and (orientation: landscape) {
  .main-img-bkg background-position {
    -o-background-size: 100% auto;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    align-items: center;
  }
}

.main-text {
  color: black;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  padding: 1.3rem;
  margin-bottom: 2rem;
  max-width: 85%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

/* Use this in your CSS to set up a background for your main-img-bkg div */
/* #enroll-section {
  background-image: -webkit-image-set(
    url("../assets/nbc/nbc-anahid-300.jpg") 1x,
    url("../assets/nbc/nbc-anahid-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/nbc/nbc-anahid-300.jpg") 1x,
    url("../assets/nbc/nbc-anahid-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/nbc/nbc-anahid-300.jpg") 300w,
    url("../assets/nbc/nbc-anahid-768.jpg") 768w,
    url("../assets/nbc/nbc-anahid-1280.jpg") 1280w,
    url("../assets/nbc/nbc-anahid-2000.jpg") 2000w
  );
  
} */

.main-img-bkg-2 {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 3rem;

  background-position: center;
}

.main-title h1 {
  font-weight: 900;
  margin-bottom: 0.9em;
}

.main-container h3 {
  font-family: "Questrial", sans-serif;
  text-transform: uppercase;
}

ul.main-bullets {
  list-style-type: circle;
}

.main-box p {
  text-align: left;
}

.main-box {
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  padding: 1.3rem;
  margin-bottom: 2rem;
}

.main-box.centered p {
  text-align: center;
}

.shadow-box p {
  text-align: left;
}

.shadow-box {
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);

  padding: 1.3rem;
  margin-bottom: 2rem;
  align-content: center;
}

.shadow-box.centered p {
  text-align: center;
}

.main-container .nyliabutton-container .btn {
  font-size: 0.4rem;
}

.main-title {
  color: white;
}

.main-text p {
  font-size: 0.6em;
}

#mission-section .main-box p {
  text-align: center;
}

@media only screen and (max-width: 320px) {
  .main-title {
    margin-top: 9rem;
    font-size: 0.9em;
  }

  .main-text p {
    font-size: 0.7em;
  }

  .main-box p {
    font-size: 0.7rem;
  }

  .main-box h2 {
    font-size: 0.6rem;
  }

  .main-container h3 {
    font-size: 0.75rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.7rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.6rem;
  }

  .accordion #highlightTitle {
    font-size: 0.5rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .main-title {
    margin-top: 8rem;
    font-size: 1em;
  }

  .main-text p {
    font-size: 0.8em;
  }

  .main-title h1 {
    font-size: 1.1rem;
  }

  .main-box p {
    font-size: 0.8rem;
  }

  .main-box h1 {
    font-size: 1.1rem;
  }
  .main-box h2 {
    font-size: 0.7rem;
  }

  .main-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.75rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }

  .accordion #highlightTitle {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .main-title {
    margin-top: 10rem;
    font-size: 1.1em;
  }

  .main-text p {
    font-size: 0.9em;
  }

  .main-title h1 {
    font-size: 1.1rem;
  }

  .main-box p {
    font-size: 0.8rem;
  }

  .main-box h1 {
    font-size: 1.1rem;
  }
  .main-box h2 {
    font-size: 0.8rem;
  }

  .main-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  .main-title img {
    max-width: 90%;
  }

  li {
    font-size: 0.75rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }
  .main-box {
    max-width: 85%;
    margin: auto;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .main-title {
    margin-top: 11rem;
    font-size: 1.2em;
  }

  .main-text p {
    font-size: 1em;
  }

  .main-title h1 {
    font-size: 1.1rem;
  }

  .main-box p {
    font-size: 0.9rem;
  }

  .main-box h1 {
    font-size: 1.1rem;
  }

  .main-box h2 {
    font-size: 0.9rem;
  }

  .main-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.8rem;
  }

  .main-box img {
    max-width: 80%;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.8rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }
  .main-box {
    max-width: 85%;
    margin: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .main-text p {
    font-size: 1.1em;
  }

  .main-title h1 {
    font-size: 1.6rem;
  }

  .main-box p {
    font-size: 0.9rem;
  }

  .main-box h1 {
    font-size: 1.2rem;
  }

  .main-box h2 {
    font-size: 0.9rem;
  }

  .main-container h3 {
    font-size: 1rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.9rem;
  }

  .main-box img {
    max-width: 60%;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.9rem;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }

  .main-box {
    max-width: 85%;
    margin: auto;
  }
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .main-text p {
    font-size: 1em;
  }

  .main-title h1 {
    font-size: 1.6rem;
  }

  .main-box p {
    font-size: 0.95rem;
  }

  .main-box h1 {
    font-size: 1.2rem;
  }
  .main-box h2 {
    font-size: 0.9rem;
  }
  .main-container h3 {
    font-size: 1rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.9rem;
  }

  .main-box img {
    max-width: 50%;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.9rem;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }

  .main-box {
    max-width: 85%;
    margin: auto;
  }
}

@media only screen and (min-width: 1201px) {
  .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .main-text p {
    font-size: 1em;
  }

  .main-title h1 {
    font-size: 2.7rem;
    font-weight: 900;
    margin-bottom: 0.9em;
  }

  .main-img-bkg .main-box p,
  .main-bkg .main-box p {
    font-size: 1.1rem;
  }

  .main-box h1 {
    font-size: 1.5rem;
  }
  .main-box h2 {
    font-size: 1.1rem;
  }

  .main-container h3 {
    font-size: 1.3rem;
    font-weight: bolder;
  }

  li {
    font-size: 1rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 1rem;
  }

  .main-box img {
    max-width: 100%;
    max-height: 100%;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }

  .main-box {
    max-width: 65%;
    margin: auto;
  }
}
