.carousel {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%;
  text-align: center;
  padding-bottom: 35px;
}

.active {
  height: 100%;
  margin: 0;
}
