@media all and (orientation: portrait) {
  #main-img-bkg background-position {
    -o-background-size: auto 100%;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    background-position: right top;
    background-size: contain;
  }
}

@media all and (orientation: landscape) {
  .main-img-bkg background-position {
    -o-background-size: 100% auto;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    align-items: center;
  }
}

.main-img {
  max-width: 500px;
  margin: auto;
}

#auditions-nbc {
  background-image: -webkit-image-set(
    url("../assets/nybc/nycb-liliani-300.jpg") 1x,
    url("../assets/nybc/nycb-liliani-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/nybc/nycb-liliani-300.jpg") 1x,
    url("../assets/nybc/nycb-liliani-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/nybc/nycb-liliani-300.jpg") 300w,
    url("../assets/nybc/nycb-liliani-768.jpg") 768w,
    url("../assets/nybc/nycb-liliani-1280.jpg") 1280w,
    url("../assets/nybc/nycb-liliani-2000.jpg") 2000w
  );
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  background-position: center right;
}

#mission-section {
  background-image: -webkit-image-set(
    url("../assets/nbc/nbc-anahid-300.jpg") 1x,
    url("../assets/nbc/nbc-anahid-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/nbc/nbc-anahid-300.jpg") 1x,
    url("../assets/nbc/nbc-anahid-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/nbc/nbc-anahid-300.jpg") 300w,
    url("../assets/nbc/nbc-anahid-768.jpg") 768w,
    url("../assets/nbc/nbc-anahid-1280.jpg") 1280w,
    url("../assets/nbc/nbc-anahid-2000.jpg") 2000w
  );
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  background-position: center;
}

#nbc-section,
#nybc-section {
  background-color: rgba(0, 0, 0, 0.85);
}

#nbc-section .main-box,
#nybc-section .main-box {
  background-color: transparent;
}

.main-img-bkg-2 {
  background-image: -webkit-image-set(
    url("../assets/nbc/nbcClaraPurple-300.jpg") 1x,
    url("../assets/nbc/nbcClaraPurple-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/nbc/nbcClaraPurple-300.jpg") 1x,
    url("../assets/nbc/nbcClaraPurple-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/nbc/nbcClaraPurple-300.jpg") 300w,
    url("../assets/nbc/nbcClaraPurple-768.jpg") 768w,
    url("../assets/nbc/nbcClaraPurple-1280.jpg") 1280w,
    url("../assets/nbc/nbcClaraPurple-2000.jpg") 2000w
  );
}

#nybcSection {
  margin-top: 3rem;
}

@media only screen and (max-width: 320px) {
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1201px) {
}
