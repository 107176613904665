.enroll-container {
  height: 100%;
  width: 100vw;
  padding-top: 5%;
  padding-bottom: 0;
  position: relative;
  text-align: center;
}

.enroll-container .nyliabutton-container {
  background-image: linear-gradient(-25deg, #2c6e7d 0%, #96b7c4 100%);
}

.enroll-container .nyliabutton-container .btn {
  width: 100%;
}

#enroll-section {
  background-image: -webkit-image-set(
    url("../assets/enrollPage/anahid-at-barre-300.JPG") 1x,
    url("../assets/enrollPage/anahid-at-barre-2000.JPG") 2x
  );
  background-image: image-set(
    url("../assets/enrollPage/anahid-at-barre-300.JPG") 1x,
    url("../assets/enrollPage/anahid-at-barre-2000.JPG") 2x
  );
  background-image: image-set(
    url("../assets/enrollPage/anahid-at-barre-300.JPG") 300w,
    url("../assets/enrollPage/anahid-at-barre-768.JPG") 768w,
    url("../assets/enrollPage/anahid-at-barre-1280.JPG") 1280w,
    url("../assets/enrollPage/anahid-at-barre-2000.JPG") 2000w
  );
}

#uniform-section {
  background-image: -webkit-image-set(
    url("../assets/enrollPage/ThreeAdultDancers-300.JPG") 1x,
    url("../assets/enrollPage/ThreeAdultDancers-2000.JPG") 2x
  );
  background-image: image-set(
    url("../assets/enrollPage/ThreeAdultDancers-300.JPG") 1x,
    url("../assets/enrollPage/ThreeAdultDancers-2000.JPG") 2x
  );
  background-image: image-set(
    url("../assets/enrollPage/ThreeAdultDancers-300.JPG") 300w,
    url("../assets/enrollPage/ThreeAdultDancers-768.JPG") 768w,
    url("../assets/enrollPage/ThreeAdultDancers-1280.JPG") 1280w,
    url("../assets/enrollPage/ThreeAdultDancers-2000.JPG") 2000w
  );
  background-position: right center;
}

#enroll-section {
  background-image: -webkit-image-set(
    url("../assets/enrollPage/ThreeBallerinas-300.JPG") 1x,
    url("../assets/enrollPage/ThreeBallerinas-2000.JPG") 2x
  );
  background-image: image-set(
    url("../assets/enrollPage/ThreeBallerinas-300.JPG") 1x,
    url("../assets/enrollPage/ThreeBallerinas-2000.JPG") 2x
  );
  background-image: image-set(
    url("../assets/enrollPage/ThreeBallerinas-300.JPG") 300w,
    url("../assets/enrollPage/ThreeBallerinas-768.JPG") 768w,
    url("../assets/enrollPage/ThreeBallerinas-1280.JPG") 1280w,
    url("../assets/enrollPage/ThreeBallerinas-2000.JPG") 2000w
  );
  background-position: right center;
}

#handbook-section {
  background-image: -webkit-image-set(
    url("../assets/enrollPage/Dracula-262-300.jpg") 1x,
    url("../assets/enrollPage/Dracula-262-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/enrollPage/Dracula-262-300.jpg") 1x,
    url("../assets/enrollPage/Dracula-262-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/enrollPage/Dracula-262-300.jpg") 300w,
    url("../assets/enrollPage/Dracula-262-768.jpg") 768w,
    url("../assets/enrollPage/Dracula-262-1280.jpg") 1280w,
    url("../assets/enrollPage/Dracula-262-2000.jpg") 2000w
  );
}

#enroll-section .main-box p {
  text-align: center;
}

.enroll-container img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.rules-section #quote {
  font-style: italic;
}

.form-check label {
  display: flex;
}

.form-control {
  color: white;
  border-radius: 0;
  font-family: "Questrial", sans-serif;
  background-color: transparent;
  /* box-shadow: 0 0 0 2px white; */
  box-shadow: 0 0 0 2px #88c88a;
  text-align: left;
}

.enroll-container .formInput,
.enroll-container .formInput:focus {
  color: white;
  border-radius: 0;
  font-family: "Questrial", sans-serif;
  background-color: transparent;
  /* box-shadow: 0 0 0 2px white; */
  box-shadow: 0 0 0 2px #88c88a;
  text-align: left;
}

@media only screen and (max-width: 320px) {
  .registration-section {
    margin: 1rem;
    margin-top: 135px;
  }
  /* 
  #enroll-section .main-title h1 {
    font-size: 0.7rem;
    font-weight: 900;
  } */

  .enroll-container h1 {
    font-size: 1em;
    font-weight: 900;
  }

  .enroll-container p {
    font-size: 0.9em;
  }

  #enrollment-page-button .btn,
  #etiquette-page-button .btn,
  #uniform-page-button .btn,
  #handbook-page-button .btn {
    font-size: 1em;
    letter-spacing: 0.3em;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .registration-section {
    margin: 1rem;
    margin-top: 135px;
  }

  .enroll-container h1 {
    font-size: 1.1em;
    font-weight: 900;
  }

  .enroll-container p {
    font-size: 1em;
  }

  #enrollment-page-button .btn,
  #etiquette-page-button .btn,
  #uniform-page-button .btn,
  #handbook-page-button .btn {
    font-size: 1.1em;
    letter-spacing: 0.3em;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .registration-section {
    margin: 1rem;
    margin-top: 145px;
    margin-bottom: 0;
    padding-bottom: 1.6rem;
  }

  .enroll-container h1 {
    font-size: 1.2em;
    font-weight: 900;
  }

  .enroll-container p {
    font-size: 1.1em;
  }

  #enrollment-page-button .btn,
  #etiquette-page-button .btn,
  #uniform-page-button .btn,
  #handbook-page-button .btn {
    font-size: 1.4em;
    letter-spacing: 0.3em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .registration-section {
    margin: 1rem;
    margin-top: 145px;
    margin-bottom: 0;
    padding-bottom: 1.6rem;
  }

  .enroll-container h1 {
    font-size: 1.3em;
    font-weight: 900;
  }

  .enroll-container p {
    font-size: 1.2em;
  }

  #enrollment-page-button .btn,
  #etiquette-page-button .btn,
  #uniform-page-button .btn,
  #handbook-page-button .btn {
    font-size: 1.5em;
    letter-spacing: 0.3em;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .registration-section {
    margin: 1rem;
    margin-top: 180px;
    margin-bottom: 0;
    padding-bottom: 1.6rem;
  }

  .enroll-container h1 {
    font-size: 1.4em;
    font-weight: 900;
  }

  .enroll-container p {
    font-size: 1.3em;
  }

  #enrollment-page-button .btn,
  #etiquette-page-button .btn,
  #uniform-page-button .btn,
  #handbook-page-button .btn {
    font-size: 1.7em;
    letter-spacing: 0.3em;
  }
}

@media only screen and (min-width: 889px) {
  /* #handbook-section {
    background-image: -webkit-image-set(
      url("../assets/enrollPage/ThreeAdultDancers-300.JPG") 1x,
      url("../assets/enrollPage/ThreeAdultDancers-2000.JPG") 2x
    );
    background-image: image-set(
      url("../assets/enrollPage/ThreeAdultDancers-300.JPG") 1x,
      url("../assets/enrollPage/ThreeAdultDancers-2000.JPG") 2x
    );
    background-image: image-set(
      url("../assets/enrollPage/ThreeAdultDancers-300.JPG") 300w,
      url("../assets/enrollPage/ThreeAdultDancers-768.JPG") 768w,
      url("../assets/enrollPage/ThreeAdultDancers-1280.JPG") 1280w,
      url("../assets/enrollPage/ThreeAdultDancers-2000.JPG") 2000w
    );
    /* background-position: right top; */
  /* } */
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .registration-section {
    margin: 1.3rem;
    margin-top: 145px;
    margin-bottom: 0;
    padding-bottom: 1.6rem;
  }

  .enroll-container h1 {
    font-size: 1.6em;
    font-weight: 900;
  }

  .enroll-container p {
    font-size: 1.3em;
  }

  #enrollment-page-button .btn,
  #etiquette-page-button .btn,
  #uniform-page-button .btn,
  #handbook-page-button .btn {
    font-size: 1.65em;
    letter-spacing: 0.3em;
  }
}

@media only screen and (min-width: 1201px) {
  .registration-section {
    margin: 1.3rem;
    margin-top: 155px;
    margin-bottom: 0;
    padding-bottom: 1.6rem;
  }

  .enroll-container h1 {
    font-size: 2.2em;
    font-weight: 900;
    margin-bottom: 0.9em;
  }

  .enroll-container p {
    font-size: 1.4em;
  }

  #enrollment-page-button .btn,
  #etiquette-page-button .btn,
  #uniform-page-button .btn,
  #handbook-page-button .btn {
    font-size: 1.75em;
    letter-spacing: 0.3em;
  }

  .enroll-container img {
    width: 55%;
    height: auto;
  }
}
