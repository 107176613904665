.nyliabutton-container {
  height: 100%;
  font-size: 12px;
  font-family: "Michroma", serif;
  --def: #96b7c4;
  --inv: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* ~~~~~~~ INIT. BTN ~~~~~~~ */

.btn {
  position: relative;
  padding: 1.4rem 4.2rem;
  padding-right: 3.1rem;
  font-size: 1.4rem;
  color: var(--inv);
  letter-spacing: 1.1rem;
  text-transform: uppercase;
  transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  user-select: none;
}

.btn:before,
.btn:after {
  content: "";
  position: absolute;
  transition: inherit;
  z-index: -1;
}

.btn:hover {
  color: var(--def);
  transition-delay: 0.5s;
}

.btn:hover:before {
  transition-delay: 0s;
}

.btn:hover:after {
  background: var(--inv);
  transition-delay: 0.35s;
}

/* From Top */

.from-top:before,
.from-top:after {
  left: 0;
  height: 0;
  width: 100%;
}

.from-top:before {
  bottom: 0;
  border: 1px solid var(--inv);
  border-top: 0;
  border-bottom: 0;
}

.from-top:after {
  top: 0;
  height: 0;
}

.from-top:hover:before,
.from-top:hover:after {
  height: 100%;
}

/* From Left */

.from-left:before,
.from-left:after {
  top: 0;
  width: 0;
  height: 100%;
}

.from-left:before {
  right: 0;
  border: 1px solid var(--inv);
  border-left: 0;
  border-right: 0;
}

.from-left:after {
  left: 0;
}

.from-left:hover:before,
.from-left:hover:after {
  width: 100%;
}

/* From Right */

.from-right:before,
.from-right:after {
  top: 0;
  width: 0;
  height: 100%;
}

.from-right:before {
  left: 0;
  border: 1px solid var(--inv);
  border-left: 0;
  border-right: 0;
}

.from-right:after {
  right: 0;
}

.from-right:hover:before,
.from-right:hover:after {
  width: 100%;
}

/* From center */

.from-center:before {
  top: 0;
  left: 50%;
  height: 100%;
  width: 0;
  border: 1px solid var(--inv);
  border-left: 0;
  border-right: 0;
}

.from-center:after {
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  background: var(--inv);
}

.from-center:hover:before {
  left: 0;
  width: 100%;
}

.from-center:hover:after {
  top: 0;
  height: 100%;
}

/* From Bottom */

.from-bottom:before,
.from-bottom:after {
  left: 0;
  height: 0;
  width: 100%;
}

.from-bottom:before {
  top: 0;
  border: 1px solid var(--inv);
  border-top: 0;
  border-bottom: 0;
}

.from-bottom:after {
  bottom: 0;
  height: 0;
}

.from-bottom:hover:before,
.from-bottom:hover:after {
  height: 100%;
}

/* ~~~~~~~~~~~~ GLOBAL SETTINGS ~~~~~~~~~~~~ */

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .nyliabutton-container .btn {
    padding-left: unset;
    padding-right: initial;
  }
}
