#sleepingBeauty2025Div .main-title {
  color: black;
}

#sleepingBeauty2025Div #performance-header-section,
#sleepingBeauty2025Div #performance-guidelines-section {
  background-image: -webkit-image-set(
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty-Fairies-300.png")
      1x,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty-Fairies-2000.png")
      2x
  );
  background-image: image-set(
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty-Fairies-300.png")
      1x,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty-Fairies-2000.png")
      2x
  );
  background-image: image-set(
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty-Fairies-300.png")
      300w,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty-Fairies-768.png")
      768w,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty-Fairies-1280.png")
      1280w,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty-Fairies-2000.png")
      2000w
  );
}

#sleepingBeauty2025Div #performance-calendar-section {
  background-image: -webkit-image-set(
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty2025BackgroundCollage-300.png")
      1x,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty2025BackgroundCollage-1280.png")
      2x
  );
  background-image: image-set(
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty2025BackgroundCollage-300.png")
      1x,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty2025BackgroundCollage-1280.png")
      2x
  );
  background-image: image-set(
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty2025BackgroundCollage-300.png")
      300w,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty2025BackgroundCollage-768.png")
      768w,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty2025BackgroundCollage-1280.png")
      1280w,
    url("../../assets/performances/SleepingBeauty2025/SleepingBeauty2025BackgroundCollage-1280.png")
      2000w
  );
}
