.upcomingevents-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  height: 100vh;
  margin: 0 auto;
  overflow: auto;
}

.upcomingevents-images {
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 40%;
  height: 100vh;
}

.upcomingevents-title {
  height: 20%;
  padding-bottom: 1em;
}

.table {
  font-family: "Questrial", sans-serif;
}

.upcomingevents-button .nyliabutton-container {
  position: relative;
  z-index: 6;
  --def: #96b7c4;
  --inv: black;
}

.upcomingevents-button {
  display: grid;
  place-items: center;
}
#events-button {
  margin-bottom: 2em;
  max-width: 75%;
  height: auto;
}

/* h1 {
  font-family: "Michroma", sans-serif;
  font-size: 40px;
  letter-spacing: 0.1vw;
} */

h2 {
  font-family: "Michroma", sans-serif;
  font-size: 30px;
  font-size: 1.4vw;
  letter-spacing: 0.1vw;
}

p {
  font-family: "Questrial", sans-serif;
  font-size: 25px;
}

.calendar {
  max-width: 85%;
  text-align: center;
  margin: auto;
}

.table {
  text-align: center;
}
.table .date {
  text-align: left;
}

.table .event {
  text-align: left;
}

.table .category {
  text-align: right;
}

.table td.note {
  /* color: rgb(44, 29, 62); */
  text-align: right;
  text-transform: capitalize;
}

.table td.special {
  /* color: rgb(44, 29, 62); */
  text-align: right;
  /* text-transform: capitalize; */
}

.table td.community {
  /* color: rgb(12, 52, 37); */
  text-align: right;
  text-transform: capitalize;
}

.table .clickableLink {
  color: #267dee;
  background-color: white;
  font-weight: bold;
}

@media only screen and (max-width: 320px) {
  .calendar tr {
    font-size: 0.7em;
  }

  .calendar .upcomingevents-title {
    padding-top: 2rem;
  }

  .upcomingevents-title p {
    font-size: 0.8em;
  }

  .upcomingevents-container {
    flex-direction: column;
    padding-top: 140px;
    height: 100%;
  }

  .upcomingevents-title {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .upcomingevents-title h1 {
    font-size: 1em;
    font-weight: 900;
    letter-spacing: 0.1em;
  }

  .upcomingevents-images {
    overflow: unset;
    width: 100%;
    height: 250px;
  }

  #upcomingevents-gallery {
    height: 250px;
  }

  .nyliabutton-container {
    padding: 0;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .calendar tr {
    font-size: 0.8em;
  }

  .calendar .upcomingevents-title {
    padding-top: 2rem;
  }

  .upcomingevents-title p {
    font-size: 0.9em;
  }
  .upcomingevents-container {
    flex-direction: column;
    padding-top: 140px;
    height: 100%;
  }

  .upcomingevents-title {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .upcomingevents-title h1 {
    font-size: 1em;
    font-weight: 900;
    letter-spacing: 0.1em;
  }

  .upcomingevents-images {
    overflow: unset;
    width: 100%;
    height: 340px;
  }

  #upcomingevents-gallery {
    height: 340px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .calendar tr {
    font-size: 0.8em;
  }

  .calendar .upcomingevents-title {
    padding-top: 2rem;
  }

  .upcomingevents-title p {
    font-size: 0.9em;
  }
  .upcomingevents-container {
    flex-direction: column;
    padding-top: 165px;
    height: 100%;
  }

  .upcomingevents-title {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .upcomingevents-title h1 {
    font-size: 1.3em;
    font-weight: 900;
    letter-spacing: 0.2em;
  }

  .upcomingevents-images {
    overflow: unset;
    width: 100%;
    height: 430px;
  }

  #upcomingevents-gallery {
    height: 430px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .calendar tr {
    font-size: 0.9em;
  }

  .calendar .upcomingevents-title {
    padding-top: 2rem;
  }

  .upcomingevents-title p {
    font-size: 1em;
  }

  .upcomingevents-container {
    flex-direction: column;
    padding-top: 170px;
    height: 100%;
  }

  .upcomingevents-title {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .upcomingevents-title h1 {
    font-size: 1.55em;
    font-weight: 900;
    letter-spacing: 0.2em;
  }

  .upcomingevents-images {
    overflow: unset;
    width: 100%;
    height: 490px;
  }

  #upcomingevents-gallery {
    height: 490px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .calendar tr {
    font-size: 0.9em;
  }

  .calendar .upcomingevents-title {
    padding-top: 2rem;
  }

  .upcomingevents-title p {
    font-size: 1em;
  }

  .upcomingevents-container {
    flex-direction: column;
    padding-top: 170px;
    height: 100%;
  }

  .upcomingevents-title {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .upcomingevents-title h1 {
    font-size: 1.55em;
    font-weight: 900;
    letter-spacing: 0.2em;
  }

  .upcomingevents-images {
    overflow: unset;
    width: 100%;
    height: 525px;
  }

  #upcomingevents-gallery {
    height: 525px;
  }

  .nyliabutton-container {
    padding: 0;
  }
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .calendar tr {
    font-size: 0.9em;
  }

  .calendar .upcomingevents-title {
    padding-top: 2rem;
  }

  .upcomingevents-title p {
    font-size: 1em;
  }

  .upcomingevents-container {
    flex-direction: column;
    padding-top: 190px;
    height: 100%;
  }

  .upcomingevents-title {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .upcomingevents-title h1 {
    font-size: 1.7em;
    font-weight: 900;
    letter-spacing: 0.1em;
  }

  .upcomingevents-images {
    overflow: unset;
    width: 100%;
    height: 580px;
  }

  #upcomingevents-gallery {
    height: 580px;
  }

  .nyliabutton-container {
    padding: 0;
  }

  h1 {
    font-family: "Michroma", sans-serif;
    font-size: 2em;
    letter-spacing: 0.1vw;
  }
}

@media only screen and (min-width: 1201px) {
  .calendar tr {
    font-size: 1em;
  }

  .calendar .upcomingevents-title {
    padding-top: 2rem;
  }

  .upcomingevents-title p {
    font-size: 1.2em;
  }

  .upcomingevents-container {
    flex-direction: column;
    padding-top: 190px;
    height: 100%;
  }

  .upcomingevents-title {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }

  .upcomingevents-title h1 {
    font-size: 1.9em;
    font-weight: 900;
    letter-spacing: 0.1em;
  }

  .upcomingevents-images {
    overflow: unset;
    width: 100%;
    height: 700px;
  }

  #upcomingevents-gallery {
    height: 700px;
  }

  .nyliabutton-container {
    padding: 0;
  }

  .upcomingevents-title {
    height: 20%;
    padding-bottom: 0.5rem;
  }

  h1 {
    font-family: "Michroma", sans-serif;
    font-size: 2em;
    letter-spacing: 0.1vw;
  }
}
