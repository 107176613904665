.navbar-container {
  width: 35%;
  height: 16vh;
  min-height: 150px;
  position: fixed;
  z-index: 5;
}

.offcanvas-header .btn-close {
  background-color: white;
}

.navbar > .container-fluid {
  justify-content: start;
  height: 100%;
  width: 100%;
}

#nylia-navbar,
img {
  height: 100%;
  width: auto;
}

.nylia-logo {
  aspect-ratio: 1 / 1;
}

img {
  height: 100% auto;
  width: auto;
}

#nylia-navbar {
  height: 100%;
  width: 100%;
}

.navbar-brand {
  height: 100%;
}

.offcanvas {
  /* margin-right: 1.5rem; */
  background-color: black;
  color: white;
}

/* .offcanvas.offcanvas-start {
  width: 35%;
  min-width: 480px;
} */

.nav-link {
  font-size: 2.7rem;
  font-family: "Michroma", sans-serif;
  padding-left: 3.5rem;
  letter-spacing: 0.3rem;
}

.nav-link .nyliabutton-container {
  position: relative;
  z-index: 6;
  --def: #96b7c4;
  --inv: black;
}

.nav-link {
  font-size: 0.6rem;
  text-align: left;
}

@media only screen and (max-width: 320px) {
  .navbar-container {
    width: 100%;
  }

  button.navbar-toggler {
    margin-left: auto;
  }

  #offcanvasNavbar-expand-false {
    width: 100%;
  }
  .modal-open {
    width: 100%;
    height: 100%;
  }

  #offcanvasNavbar-expand-false .nylia-logo {
    width: 55%;
    max-height: 30%;
  }

  .offcanvas-header {
    width: 100vw;
  }

  .offcanvas-body {
    width: 100vw;
  }

  .offcanvas.offcanvas-start {
    width: 35%;
    min-width: 150px;
  }

  #navigation-body {
    width: 100%;
  }

  .nyliabutton-container {
    /* padding: 1rem; */
  }

  .nyliabutton-container .btn {
    font-size: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    /* padding-right: 0rem;
    padding-left: 01rem; */
    letter-spacing: 0.3rem;
  }

  .nav-link h1 {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .navbar-container {
    width: 100%;
  }

  button.navbar-toggler {
    margin-left: auto;
  }

  #offcanvasNavbar-expand-false {
    width: 100%;
  }
  .modal-open {
    width: 100%;
    height: 100%;
  }

  #offcanvasNavbar-expand-false .nylia-logo {
    width: 60%;
    max-height: 30%;
  }

  .offcanvas-header {
    width: 100vw;
  }

  .offcanvas-body {
    width: 100vw;
  }

  .offcanvas.offcanvas-start {
    width: 35%;
    min-width: 150px;
  }

  #navigation-body {
    width: 100%;
  }

  .nyliabutton-container {
    /* padding: 1rem; */
  }

  .nyliabutton-container .btn {
    font-size: 0.8rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    /* padding-right: 0rem;
    padding-left: 01rem; */
    letter-spacing: 0.8rem;
  }

  a.nav-link h1 {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .navbar-container {
    width: 100%;
  }

  button.navbar-toggler {
    margin-left: auto;
  }

  #offcanvasNavbar-expand-false {
    width: 100%;
  }
  .modal-open {
    width: 100%;
    height: 100%;
  }

  .nylia-logo {
    min-height: 175px;
  }

  #offcanvasNavbar-expand-false .nylia-logo {
    width: 60%;
    max-height: 30%;
  }

  .offcanvas-header {
    width: 95vw;
  }

  .offcanvas-body {
    width: 95vw;
  }

  .offcanvas.offcanvas-start {
    width: 35%;
    min-width: 150px;
  }

  #navigation-body {
    width: 85%;
  }

  .nyliabutton-container {
    padding: 1rem;
  }

  .nav-link h1 {
    font-size: 0.7rem;
  }

  .nyliabutton-container .btn {
    font-size: 0.7rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0rem;
    padding-left: 1rem;
    letter-spacing: 0.6rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .navbar-container {
    width: 100%;
  }

  button.navbar-toggler {
    margin-left: auto;
  }

  #offcanvasNavbar-expand-false {
    width: 65%;
  }
  .modal-open {
    width: 60%;
    height: 100%;
  }

  .nylia-logo {
    min-height: 175px;
  }

  #offcanvasNavbar-expand-false .nylia-logo {
    width: 80%;
    max-height: 30%;
  }

  .offcanvas-header {
    width: 55vw;
  }

  .offcanvas-body {
    width: 55vw;
  }

  .offcanvas.offcanvas-start {
    width: 35%;
    min-width: 150px;
  }

  #navigation-body {
    width: 85%;
  }

  .nyliabutton-container {
    padding: 1rem;
  }

  .nav-link h1 {
    font-size: 0.75rem;
  }

  .nyliabutton-container .btn {
    font-size: 0.83rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0rem;
    padding-left: 1rem;
    letter-spacing: 0.6rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .navbar-container {
    width: 100%;
  }

  button.navbar-toggler {
    margin-left: auto;
  }

  #offcanvasNavbar-expand-false {
    width: 55%;
  }
  .modal-open {
    width: 55%;
    height: 100%;
  }

  .nylia-logo {
    min-height: 200px;
  }

  #offcanvasNavbar-expand-false .nylia-logo {
    width: 69%;
    max-height: 30%;
  }

  .offcanvas-header {
    width: 50vw;
  }

  .offcanvas-body {
    width: 50vw;
  }

  .offcanvas.offcanvas-start {
    width: 35%;
    min-width: 150px;
  }

  #navigation-body {
    width: 100%;
  }

  .nyliabutton-container {
    padding: 1rem;
  }

  .nyliabutton-container .btn {
    font-size: 0.85rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    padding-right: 0rem;
    padding-left: 1.3rem;
    letter-spacing: 0.6rem;
  }
  .nav-link h1 {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .navbar-container {
    width: 100%;
  }

  button.navbar-toggler {
    margin-left: auto;
  }

  #offcanvasNavbar-expand-false {
    width: 55%;
  }
  .modal-open {
    width: 55%;
    height: 100%;
  }

  .nylia-logo {
    min-height: 200px;
  }

  #offcanvasNavbar-expand-false .nylia-logo {
    width: 69%;
    max-height: 30%;
  }

  .offcanvas-header {
    width: 50vw;
  }

  .offcanvas-body {
    width: 50vw;
  }

  .offcanvas.offcanvas-start {
    width: 35%;
    min-width: 150px;
  }

  #navigation-body {
    width: 85%;
  }

  .nyliabutton-container {
    padding: 1rem;
  }

  .nyliabutton-container .btn {
    font-size: 0.85rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    padding-right: 0rem;
    padding-left: 1.3rem;
    letter-spacing: 0.6rem;
  }

  .nav-link h1 {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 1201px) {
  .navbar-container {
    width: 100%;
  }

  button.navbar-toggler {
    margin-left: auto;
  }

  #offcanvasNavbar-expand-false {
    width: 35%;
  }
  .modal-open {
    width: 35%;
    height: 100%;
  }

  .nylia-logo {
    min-height: 200px;
  }

  #offcanvasNavbar-expand-false .nylia-logo {
    width: 76%;
    height: auto;
  }

  .offcanvas-header {
    width: 30vw;
  }

  .offcanvas-body {
    width: 30vw;
  }

  .offcanvas.offcanvas-start {
    width: 35%;
    min-width: 150px;
  }

  #navigation-body {
    width: 85%;
  }

  .nyliabutton-container {
    padding: 1rem;
  }

  .nyliabutton-container .btn {
    font-size: 0.85rem;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    padding-right: 0.3rem;
    padding-left: 1.3rem;
    letter-spacing: 0.6rem;
  }

  .nav-link h1 {
    font-size: 0.9rem;
  }
}
