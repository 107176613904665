.performances-container {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.performances-container .carousel-caption {
  top: 50%;
  transform: translateY(-50%);
}

.performances-container .carousel-item h2 {
  font-weight: bold;
}

.performances-container .carousel-item h3 {
  font-family: "Michroma", sans-serif;
}
.performances-container .carousel-item .nyliabutton-container .btn {
  background-color: rgba(0, 0, 0, 0.6);
}

.performances-container .performanceButtons .btn {
  font-weight: bold;
  font-family: "Michroma", sans-serif;
  letter-spacing: normal;
  padding: 0.5rem;
}

.performances-gallery-section {
  width: 100vw;
  height: auto;
}

.performances-gallery-section img {
  width: 100%;
  height: auto;
}

.perfGalleryImgContainer {
  position: relative;
}

.perfGalleryImgContainer img {
  display: block;
  width: 100%;
  height: auto;
}

.perfGalleryImgOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #043849;
}

.perfGalleryImgContainer:hover .perfGalleryImgOverlay {
  opacity: 0.8;
}

.perfGalleryImgText {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Michroma", sans-serif;
}

.perfGalleryImgText h2,
.perfGalleryImgText p {
  font-weight: bold;
}

.performances-container .carousel-item img {
  height: 100%;
  max-height: 500px;
  object-fit: cover;
  object-position: left 24%;
  filter: brightness(30%);
}

.performances-container .carousel-caption {
  bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

@media only screen and (max-width: 320px) {
  .performances-container .carousel-item h1 {
    font-size: 0.9rem;
  }
  .performances-container .carousel-item h2 {
    font-size: 0.8rem;
  }
  .performances-container .carousel-item h3 {
    font-size: 0.5rem;
  }
  .performances-container .carousel-item h4 {
    font-size: 0.5rem;
  }
  .performances-container .carousel-item p {
    font-size: 0.5rem;
  }

  .performances-container .performanceButtons .btn {
    font-size: 0.4rem;
    margin: 0.1rem;
    padding: 0.4rem;
  }

  .perfGalleryImgText h2 {
    font-size: 1.5rem;
  }

  .perfGalleryImgText p {
    font-size: 0.8rem;
  }

  .performances-container .carousel-item img {
    max-height: 350px;
  }

  .performances-container .nyliabutton-container {
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 45%;
  }
}
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .performances-container .carousel-item h1 {
    font-size: 1.1rem;
  }
  .performances-container .carousel-item h2 {
    font-size: 0.9rem;
  }
  .performances-container .carousel-item h3 {
    font-size: 0.6rem;
  }
  .performances-container .carousel-item h4 {
    font-size: 0.6rem;
  }
  .performances-container .carousel-item p {
    font-size: 0.7rem;
  }

  .performances-container .performanceButtons .btn {
    font-size: 0.5rem;
    margin: 0.1rem;
    padding: 0.4rem;
  }

  .perfGalleryImgText h2 {
    font-size: 1.6rem;
  }

  .perfGalleryImgText p {
    font-size: 0.9rem;
  }

  .performances-container .carousel-item img {
    max-height: 350px;
  }

  .performances-container .nyliabutton-container {
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 45%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .performances-container .carousel-item h1 {
    font-size: 1.3rem;
  }
  .performances-container .carousel-item h2 {
    font-size: 1.1rem;
  }
  .performances-container .carousel-item h3 {
    font-size: 0.8rem;
  }
  .performances-container .carousel-item h4 {
    font-size: 0.8rem;
  }
  .performances-container .carousel-item p {
    font-size: 0.8rem;
  }

  .performances-container .performanceButtons .btn {
    font-size: 0.5rem;
    margin: 0.1rem;
    padding: 0.4rem;
  }

  .perfGalleryImgText h2 {
    font-size: 1.6rem;
  }

  .perfGalleryImgText p {
    font-size: 0.9rem;
  }
  .performances-container .carousel-item img {
    max-height: 350px;
  }

  .performances-container .nyliabutton-container {
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 45%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .performances-container .carousel-item h1 {
    font-size: 1.4rem;
  }
  .performances-container .carousel-item h2 {
    font-size: 1.3rem;
  }
  .performances-container .carousel-item h3 {
    font-size: 0.9rem;
  }
  .performances-container .carousel-item h4 {
    font-size: 0.95rem;
  }
  .performances-container .carousel-item p {
    font-size: 0.95rem;
  }

  .performances-container .performanceButtons .btn {
    font-size: 0.5rem;
    margin: 0.1rem;
    padding: 0.4rem;
  }

  .perfGalleryImgText h2 {
    font-size: 1rem;
  }

  .perfGalleryImgText p {
    font-size: 0.9rem;
  }
  .performances-container .carousel-item img {
    max-height: 350px;
  }

  .performances-container .nyliabutton-container {
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .performances-container .carousel-item h1 {
    font-size: 1.4rem;
  }
  .performances-container .carousel-item h2 {
    font-size: 1.3rem;
  }
  .performances-container .carousel-item h3 {
    font-size: 0.9rem;
  }
  .performances-container .carousel-item h4 {
    font-size: 0.95rem;
  }
  .performances-container .carousel-item p {
    font-size: 0.95rem;
  }

  .performances-container .performanceButtons .btn {
    font-size: 0.5rem;
    margin: 0.1rem;
    padding: 0.4rem;
  }

  .perfGalleryImgText h2 {
    font-size: 1rem;
  }

  .perfGalleryImgText p {
    font-size: 0.9rem;
  }
  .performances-container .carousel-item img {
    max-height: 500px;
  }

  .performances-container .nyliabutton-container {
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 45%;
  }
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .performances-container .carousel-item h1 {
    font-size: 1.4rem;
  }
  .performances-container .carousel-item h2 {
    font-size: 1.6rem;
  }
  .performances-container .carousel-item h3 {
    font-size: 01rem;
  }
  .performances-container .carousel-item h4 {
    font-size: 01rem;
  }
  .performances-container .carousel-item p {
    font-size: 1rem;
  }

  .performances-container .performanceButtons .btn {
    font-size: 0.7rem;
    margin: 0.3rem;
    padding: 0.4rem;
  }

  .perfGalleryImgText h2 {
    font-size: 1rem;
  }

  .perfGalleryImgText p {
    font-size: 0.9rem;
  }
  .performances-container .carousel-item img {
    max-height: 520px;
  }

  .performances-container .nyliabutton-container {
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 45%;
  }
}

@media only screen and (min-width: 1201px) {
  .performances-container .carousel-item h1 {
    font-size: 1.4rem;
  }
  .performances-container .carousel-item h2 {
    font-size: 1.7rem;
  }
  .performances-container .carousel-item h3 {
    font-size: 01rem;
  }
  .performances-container .carousel-item h4 {
    font-size: 01rem;
  }
  .performances-container .carousel-item p {
    font-size: 1rem;
  }

  .performances-container .performanceButtons .btn {
    font-size: 0.7rem;
    margin: 0.3rem;
    padding: 0.4rem;
  }

  .perfGalleryImgText h2 {
    font-size: 1.3rem;
  }

  .perfGalleryImgText p {
    font-size: 01rem;
  }
  .performances-container .carousel-item img {
    max-height: 550px;
  }

  .performances-container .nyliabutton-container {
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 45%;
  }
}
