.contact-form {
  text-align: center;
  max-width: 300px;
  width: 90%;
  margin: 20px auto;
}

.row {
  margin-bottom: 1rem;
}

.formControl {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  background-clip: padding-box;
}

.formInput {
  padding: 25px 10px;
  color: white;
  font-size: 1.3rem;
}
.formInput,
.formInput:focus {
  background-color: transparent;
  color: white;
}

.formInput::placeholder {
  color: white;
  opacity: 0.7;
}

.errorMessage {
  color: white;
}

.nyliabutton-container {
  position: relative;
  z-index: 6;
}

@media only screen and (max-width: 320px) {
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
}

@media only screen and (min-width: 1201px) {
}
