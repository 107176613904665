.team-container {
  height: 100%;
  width: 100vw;
  /* padding: 5%; */
  position: relative;
  text-align: center;
  background-color: rgba(3, 34, 17, 0.577);
}

.card-group {
  padding: 1em;
}

.TeamCard {
  max-width: 400px;
  padding: 1em;
  color: white;
}

.TeamCard .card {
  background-color: rgb(0, 0, 0, 0.8);
}

.TeamCard .card-footer {
  background-color: rgb(0, 0, 0, 0.6);
}

.TeamCard .card-body {
  color: white;
}

#team-card-bkg {
  /* background-color: rgba(0, 0, 0, 0.457); */
  /* margin: 4rem; */
  /* background-color: rgb(0, 0, 0); */
}

#team-label {
  font-weight: bold;
}

@media only screen and (max-width: 320px) {
  .team-container {
    padding-top: 175px;
  }

  .team-container p {
    font-size: 0.7rem;
  }

  .team-container h3 {
    font-size: 1rem;
  }

  .team-container .h5 {
    font-size: 0.9rem;
  }

  .team-container small {
    font-size: 0.75rem;
  }

  .team-container h1 {
    font-size: 1.5rem;
  }

  .card-text {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .team-container {
    padding-top: 150px;
  }

  .team-container p {
    font-size: 0.8rem;
  }

  .team-container h3 {
    font-size: 1rem;
  }

  .team-container .h5 {
    font-size: 1rem;
  }

  .team-container small {
    font-size: 0.75rem;
  }

  .team-container h1 {
    font-size: 1.5rem;
  }

  #team-label {
    font-size: 1.5rem;
  }

  .team-container .card-text {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .team-container {
    padding-top: 175px;
  }

  .team-container p {
    font-size: 1rem;
  }

  .team-container h3 {
    font-size: 1rem;
  }

  .team-container h1 {
    font-size: 1.5rem;
  }

  .team-container .card-text {
    font-size: 1rem;
  }

  .team-container #team-label {
    font-size: 1.75rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .team-container {
    padding-top: 175px;
  }

  .team-container p {
    font-size: 1rem;
  }

  .team-container h3 {
    font-size: 1rem;
  }

  .team-container h1 {
    font-size: 1.5rem;
  }

  .card-text {
    font-size: 1rem;
  }
  #team-label {
    font-size: 1.75rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .team-container {
    padding-top: 175px;
  }

  .team-container p {
    font-size: 1rem;
  }

  .team-container h3 {
    font-size: 1rem;
  }

  .team-container h1 {
    font-size: 1.5rem;
  }

  .card-text {
    font-size: 1rem;
  }
  #team-label {
    font-size: 1.75rem;
  }
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .team-container {
    padding-top: 150px;
  }

  .team-container p {
    font-size: 1rem;
  }

  .team-container h3 {
    font-size: 1rem;
  }

  .team-container h1 {
    font-size: 1.5rem;
  }

  .card-text {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1201px) {
  .team-container {
    padding-top: 150px;
  }

  .team-container p {
    font-size: 1rem;
  }

  .team-container h3 {
    font-size: 1rem;
  }

  .team-container h1 {
    font-size: 1.5rem;
  }

  .card-text {
    font-size: 1rem;
  }
}
