#quicklinks-section {
  background-image: -webkit-image-set(
    url("../../assets/quicklinks/anahid-bkg-300.JPG") 1x,
    url("../../assets/quicklinks/anahid-bkg-2000.JPG") 2x
  );
  background-image: image-set(
    url("../../assets/quicklinks/anahid-bkg-300.JPG") 1x,
    url("../../assets/quicklinks/anahid-bkg-2000.JPG") 2x
  );
  background-image: image-set(
    url("../../assets/quicklinks/anahid-bkg-300.JPG") 300w,
    url("../../assets/quicklinks/anahid-bkg-768.JPG") 768w,
    url("../../assets/quicklinks/anahid-bkg-1280.JPG") 1280w,
    url("../../assets/quicklinks/anahid-bkg-2000.JPG") 2000w
  );
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  background-position: center;
}

@media all and (orientation: portrait) {
  #main-img-bkg background-position {
    /* background-repeat: no-repeat;
    background-size: cover;
    position: relative; */
    -o-background-size: auto 100%;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    background-position: right top;
    background-size: contain;
  }
}

@media all and (orientation: landscape) {
  .main-img-bkg background-position {
    -o-background-size: 100% auto;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    align-items: center;
  }
}

.main-text {
  color: black;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  padding: 1.3rem;
  margin-bottom: 2rem;
  max-width: 85%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.main-img {
  max-width: 500px;
  margin: auto;
}

#mission-section {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  background-position: center;
}

.main-img-bkg-2 {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 3rem;

  background-position: center;
}

#nybcSection {
  margin-top: 3rem;
}

.main-container h3 {
  font-family: "Questrial", sans-serif;
  text-transform: uppercase;
}

ul.main-bullets {
  list-style-type: circle;
}

.main-box p {
  text-align: left;
}

.main-box.centered p {
  text-align: center;
}

.shadow-box p {
  text-align: left;
}

.shadow-box {
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);

  padding: 1.3rem;
  margin-bottom: 2rem;
  align-content: center;
}

.shadow-box.centered p {
  text-align: center;
}

.main-container .nyliabutton-container .btn {
  font-size: 0.4rem;
}

.main-title {
  color: white;
}

.main-text p {
  font-size: 0.6em;
}

#mission-section .main-box p {
  text-align: center;
}

@media only screen and (max-width: 320px) {
  .main-title {
    margin-top: 9rem;
    font-size: 0.9em;
  }

  .main-text p {
    font-size: 0.7em;
  }

  .main-box p {
    font-size: 0.7rem;
  }

  .main-box h2 {
    font-size: 0.6rem;
  }

  .main-container h3 {
    font-size: 0.75rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.7rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.6rem;
  }

  .accordion #highlightTitle {
    font-size: 0.5rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .main-title {
    margin-top: 8rem;
    font-size: 1em;
  }

  .main-text p {
    font-size: 0.8em;
  }

  .main-box p {
    font-size: 0.8rem;
  }

  .main-box h1 {
    font-size: 1.1rem;
  }
  .main-box h2 {
    font-size: 0.7rem;
  }

  .main-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.75rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }

  .accordion #highlightTitle {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .main-title {
    margin-top: 10rem;
    font-size: 1.1em;
  }

  .main-text p {
    font-size: 0.9em;
  }

  .main-box p {
    font-size: 0.8rem;
  }

  .main-box h1 {
    font-size: 1.1rem;
  }
  .main-box h2 {
    font-size: 0.8rem;
  }

  .main-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  .main-title img {
    max-width: 90%;
  }

  li {
    font-size: 0.75rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .main-title {
    margin-top: 11rem;
    font-size: 1.2em;
  }

  .main-text p {
    font-size: 1em;
  }

  .main-box p {
    font-size: 0.9rem;
  }

  .main-box h1 {
    font-size: 1.1rem;
  }

  .main-box h2 {
    font-size: 0.9rem;
  }

  .main-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.8rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.8rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .main-text p {
    font-size: 1.1em;
  }

  .main-box p {
    font-size: 0.9rem;
  }

  .main-box h1 {
    font-size: 1.2rem;
  }

  .main-box h2 {
    font-size: 0.9rem;
  }

  .main-container h3 {
    font-size: 1rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.9rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.9rem;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 889px) {
  #quicklinks-section {
    background-image: -webkit-image-set(
      url("../../assets/quicklinks/youth-villagers-bkg-300.png") 1x,
      url("../../assets/quicklinks/youth-villagers-bkg-2000.png") 2x
    );
    background-image: image-set(
      url("../../assets/quicklinks/youth-villagers-bkg-300.png") 1x,
      url("../../assets/quicklinks/youth-villagers-bkg-2000.png") 2x
    );
    background-image: image-set(
      url("../../assets/quicklinks/youth-villagers-bkg-300.png") 300w,
      url("../../assets/quicklinks/youth-villagers-bkg-768.png") 768w,
      url("../../assets/quicklinks/youth-villagers-bkg-1280.png") 1280w,
      url("../../assets/quicklinks/youth-villagers-bkg-2000.png") 2000w
    );
  }
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .main-text p {
    font-size: 1em;
  }

  .main-box p {
    font-size: 0.95rem;
  }

  .main-box h1 {
    font-size: 1.2rem;
  }
  .main-box h2 {
    font-size: 0.9rem;
  }
  .main-container h3 {
    font-size: 1rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.9rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 0.9rem;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 1201px) {
  .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .main-text p {
    font-size: 1em;
  }

  .main-box p {
    font-size: 1.1rem;
  }

  .main-box h1 {
    font-size: 1.5rem;
  }
  .main-box h2 {
    font-size: 1.1rem;
  }

  .main-container h3 {
    font-size: 1.3rem;
    font-weight: bolder;
  }

  li {
    font-size: 1rem;
  }

  .main-container .nyliabutton-container .btn {
    font-size: 1rem;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }
}
