.classes-container {
  background-image: linear-gradient(-25deg, #1b2925 0%, #78aca3 100%);
}

#cur-class-rgstr {
  font-style: oblique;
}

.class-schedule-button {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.class-title {
  text-align: center;
}

#cur-class-description {
  white-space: pre-wrap;
}

.learn-more-classes {
  text-align: center;
  width: 100%;
  color: white;
}

.page-title {
  color: white;
}

#cur-class-ages {
  color: white;
}

.class-title h1 {
  font-weight: 900;
  color: white;
  font-size: 1.7em;
}

.learn-more-classes .dropdown {
  padding: 0rem;
}
.learn-more-classes .btn-group-vertical {
  width: 100%;
}

.learn-more-classes .btn-group-vertical .btn {
  background-color: rgba(7, 49, 50, 0.75);
  border-radius: 0;
}

.learn-more-classes .dropdown,
.learn-more-classes .btn-group-vertical .btn {
  color: white;
  font-family: "Michroma", sans-serif;
  letter-spacing: normal;
  font-weight: bold;
  border-radius: 0;
  border-color: black;
}

.full-schedule {
  width: 100%;
  text-align: center;
  color: white;
}

.full-schedule img {
  width: 100%;
}

@media only screen and (max-width: 320px) {
  .learn-more-classes {
    padding-top: 1rem;
  }

  .learn-more-classes p {
    font-size: 0.7em;
  }

  .full-schedule h1 {
    padding: 1rem;
    padding-top: 1.8rem;
    font-size: 1em;
    font-weight: bolder;
  }

  .dropdown button.dropdown-item {
    font-size: 0.5em;
  }

  .learn-more-classes h2 {
    font-size: 0.8rem;
    color: white;
  }

  .learn-more-classes .btn-group-vertical {
    width: 75%;
    margin: auto;
  }

  .learn-more-classes .btn-group-vertical .btn {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 0.5rem;
  }

  .class-title h1 {
    font-size: 1.1em;
  }

  .classes-container {
    height: 100%;
    width: 100vw;
  }

  .left-listings {
    display: none;
  }

  .mobile-class-dropdown {
    width: 100%;
  }

  .dropdown {
    width: 100%;
    padding: 1rem;
    font-family: "Michroma", serif;
    text-align: center;
  }

  .dropdown button {
    width: 100%;
    font-size: 0.8em;
    letter-spacing: 0.2em;
    text-align: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: 900;
  }

  .btn.show {
    background-color: transparent;
    border-color: white;
  }

  .dropdown .btn:hover {
    background-color: white;
    border-color: white;
  }

  button.dropdown-item {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    padding-right: 0;
    padding-left: 0;
    color: black;
    font-size: 0.75em;
  }
  .dropdown button.dropdown-item {
    font-weight: normal;
  }

  .right-info {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
    padding-top: 0;
  }

  .class-desc-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1em;
  }

  #cur-class-ages {
    font-size: 1em;
  }

  .page-title {
    order: 1;
    padding-top: 160px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }

  .page-title h1 {
    font-size: 1.7em;
    font-weight: 900;
    color: white;
  }

  .page-title p {
    font-size: 1em;
  }

  .class-image {
    order: 2;
  }

  .class-image img {
    width: 100%;
    height: auto;
    padding: 1rem;
    padding-top: 0;
  }

  .class-info {
    order: 3;
    text-align: center;
  }

  .class-schedule-button {
    order: 4;
  }

  .class-descriptions-button {
    order: 5;
  }

  #cur-class-sched1,
  #cur-class-sched3 {
    font-weight: 900;
    font-family: "Michroma", serif;
    font-size: 0.7rem;
    margin: 0;
    color: white;
  }

  #cur-class-sched2,
  #cur-class-sched4 {
    font-size: 0.86rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  #cur-class-rgstr {
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.5);
  }

  #cur-class-description {
    font-size: 0.8em;
    color: white;
    white-space: pre-wrap;
  }

  .class-schedule-button .btn {
    font-size: 0.7em;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-right: 0.4rem;
    padding-left: 01rem;
    letter-spacing: 0.3rem;
  }

  .class-descriptions-button .btn {
    font-size: 0.7em;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-right: 0.4rem;
    padding-left: 01rem;
    letter-spacing: 0.3rem;
  }
  .desktop-page-title {
    display: none;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .learn-more-classes {
    padding-top: 1rem;
  }

  .learn-more-classes p {
    font-size: 0.8em;
  }

  .full-schedule h1 {
    padding: 1rem;
    padding-top: 1.8rem;
    font-size: 1em;
    font-weight: bolder;
  }

  .dropdown button.dropdown-item {
    font-size: 0.6em;
  }

  .learn-more-classes h2 {
    font-size: 0.8rem;
    color: white;
  }

  .learn-more-classes .btn-group-vertical {
    width: 75%;
    margin: auto;
  }

  .learn-more-classes .btn-group-vertical .btn {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 0.5rem;
  }

  .class-title h1 {
    font-size: 1.1em;
  }
  .classes-container {
    height: 100%;
    width: 100vw;
  }

  .left-listings {
    display: none;
  }

  .mobile-class-dropdown {
    width: 100%;
  }

  #cur-class-ages {
    font-size: 1.4em;
  }

  .dropdown {
    width: 100%;
    padding: 1rem;
    font-family: "Michroma", serif;
    text-align: center;
  }

  .dropdown button {
    width: 100%;
    font-size: 0.94em;
    letter-spacing: 0.2em;
    text-align: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: 900;
  }

  .btn.show {
    background-color: transparent;
    border-color: white;
  }

  .dropdown .btn:hover {
    background-color: white;
    border-color: white;
  }

  button.dropdown-item {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    padding-right: 0;
    padding-left: 0;
    color: black;
    font-size: 0.8em;
  }
  .dropdown button.dropdown-item {
    font-weight: normal;
  }

  .right-info {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
    padding-top: 0;
  }

  .class-desc-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.4em;
  }

  .page-title {
    order: 1;
    padding-top: 130px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }

  .page-title h1 {
    font-size: 1.7em;
    font-weight: 900;
    color: white;
  }

  .page-title p {
    font-size: 1em;
  }

  .class-image {
    order: 2;
  }

  .class-image img {
    width: 100%;
    height: auto;
    padding: 1rem;
    padding-top: 0;
  }

  .class-info {
    order: 3;
    text-align: center;
  }

  .class-schedule-button {
    order: 4;
  }

  .class-descriptions-button {
    order: 5;
  }

  #cur-class-sched1,
  #cur-class-sched3 {
    font-weight: 900;
    font-family: "Michroma", serif;
    font-size: 0.75rem;
    margin: 0;
    color: white;
  }

  #cur-class-sched2,
  #cur-class-sched4 {
    font-size: 0.9rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  #cur-class-rgstr {
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.5);
  }

  #cur-class-description {
    font-size: 0.9em;
    color: white;
  }

  .class-schedule-button .btn {
    font-size: 0.8em;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-right: 0.4rem;
    padding-left: 01rem;
    letter-spacing: 0.3rem;
  }

  .class-descriptions-button .btn {
    font-size: 0.8em;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-right: 0.4rem;
    padding-left: 01rem;
    letter-spacing: 0.3rem;
  }
  .desktop-page-title {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .learn-more-classes {
    padding-top: 1.2rem;
  }

  .learn-more-classes p {
    font-size: 0.9em;
  }

  .full-schedule h1 {
    padding: 1rem;
    padding-top: 1.8rem;
    font-size: 1.5em;
    font-weight: bolder;
  }

  .dropdown button.dropdown-item {
    font-size: 0.6em;
  }

  .learn-more-classes h2 {
    font-size: 1rem;
    color: white;
  }

  .learn-more-classes .btn-group-vertical {
    width: 75%;
    margin: auto;
  }

  .learn-more-classes .btn-group-vertical .btn {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 0.6rem;
  }
  .class-title h1 {
    font-size: 1.5em;
  }

  .desktop-page-title {
    display: none;
  }
  .classes-container {
    height: 100%;
    width: 100vw;
  }

  .left-listings {
    display: none;
  }

  .mobile-class-dropdown {
    width: 100%;
  }

  .dropdown {
    width: 100%;
    padding: 1rem;
    font-family: "Michroma", serif;
    text-align: center;
  }

  .dropdown button {
    width: 100%;
    font-size: 0.94em;
    letter-spacing: 0.2em;
    text-align: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: 900;
  }

  #cur-class-ages {
    margin-bottom: 0;
  }

  .btn.show {
    background-color: transparent;
    border-color: white;
  }

  .dropdown .btn:hover {
    background-color: white;
    border-color: white;
  }

  button.dropdown-item {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    padding-right: 0;
    padding-left: 0;
    color: black;
    font-size: 0.8em;
  }
  .dropdown button.dropdown-item {
    font-weight: normal;
  }

  .right-info {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
    padding-top: 0;
  }

  .class-desc-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.4em;
  }

  .page-title {
    order: 1;
    padding-top: 160px;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    text-align: center;
  }

  .page-title h1 {
    font-size: 1.9em;
    font-weight: 900;
    color: white;
  }

  .page-title p {
    font-size: 1.08em;
  }

  .class-image {
    order: 2;
  }

  .class-image img {
    width: 100%;
    height: auto;
    padding: 1.1rem;
    padding-top: 1rem;
  }

  .class-info {
    order: 3;
    text-align: center;
  }

  .class-schedule-button {
    order: 4;
  }

  .class-descriptions-button {
    order: 5;
  }

  #cur-class-sched1,
  #cur-class-sched3 {
    font-weight: 900;
    font-family: "Michroma", serif;
    font-size: 0.92rem;
    margin: 0;
    color: white;
  }

  #cur-class-sched2,
  #cur-class-sched4 {
    font-size: 0.92rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  #cur-class-rgstr {
    font-size: 0.96em;
    color: rgba(255, 255, 255, 0.5);
  }

  #cur-class-description {
    font-size: 0.95em;
    color: white;
  }

  .class-schedule-button .btn {
    font-size: 01.25em;
    margin-top: 1rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    padding-right: 0.4rem;
    padding-left: 01rem;
    letter-spacing: 0.3rem;
  }

  .class-descriptions-button .btn {
    margin-top: 0rem;
    font-size: 1.25em;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    padding-right: 0.4rem;
    padding-left: 01rem;
    letter-spacing: 0.3rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .learn-more-classes {
    padding-top: 1.4rem;
  }

  .learn-more-classes p {
    font-size: 1em;
  }

  .full-schedule h1 {
    padding: 1rem;
    padding-top: 1.8rem;
    font-size: 1.5em;
    font-weight: bolder;
  }

  .dropdown button.dropdown-item {
    font-size: 0.6em;
  }

  .learn-more-classes h2 {
    font-size: 1rem;
    color: white;
  }

  .learn-more-classes .btn-group-vertical {
    width: 75%;
    max-width: 300px;
    margin: auto;
  }

  .learn-more-classes .btn-group-vertical .btn {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 0.6rem;
  }
  .desktop-page-title {
    display: none;
  }
  .classes-container {
    height: 100%;
    width: 100vw;
  }

  .left-listings {
    display: none;
  }

  .mobile-class-dropdown {
    width: 100%;
  }

  #cur-class-ages {
    font-size: 1.6em;
    margin-bottom: 0;
  }

  .dropdown {
    width: 100%;
    padding: 1rem;
    font-family: "Michroma", serif;
    text-align: center;
  }

  .dropdown button {
    width: 100%;
    font-size: 0.94em;
    letter-spacing: 0.2em;
    text-align: center;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: 900;
  }

  .btn.show {
    background-color: transparent;
    border-color: white;
  }

  .dropdown .btn:hover {
    background-color: white;
    border-color: white;
  }

  button.dropdown-item {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    padding-right: 0;
    padding-left: 0;
    color: black;
    font-size: 0.8em;
  }
  .dropdown button.dropdown-item {
    font-weight: normal;
  }

  .right-info {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
    padding-top: 0;
  }

  .class-desc-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2.3em;
    padding-top: 1.2em;
  }

  .page-title {
    order: 1;
    padding-top: 160px;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    text-align: center;
  }

  .page-title h1 {
    font-size: 2.1em;
    font-weight: 900;
    color: white;
  }

  .page-title p {
    font-size: 1.2em;
  }

  .class-image {
    order: 2;
  }

  .class-image img {
    width: 100%;
    height: auto;
    padding: 1.1rem;
    padding-top: 1.2rem;
  }

  .class-info {
    order: 3;
    text-align: center;
  }

  .class-schedule-button {
    order: 4;
  }

  .class-descriptions-button {
    order: 5;
  }

  #cur-class-sched1,
  #cur-class-sched3 {
    font-weight: 900;
    font-family: "Michroma", serif;
    font-size: 1.15rem;
    margin: 0;
    color: white;
  }

  #cur-class-sched2,
  #cur-class-sched4 {
    font-size: 1.2rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  #cur-class-rgstr {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.5);
  }

  #cur-class-description {
    font-size: 1.1em;
    color: white;
  }

  .class-schedule-button .btn {
    font-size: 01.5em;
    margin-top: 1.1rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 0.4rem;
    padding-left: 1.1rem;
    letter-spacing: 0.3rem;
  }

  .class-descriptions-button .btn {
    margin-top: 0rem;
    font-size: 1.5em;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 0.4rem;
    padding-left: 1.1rem;
    letter-spacing: 0.3rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .full-schedule img {
    width: 100%;
    max-width: 525px;
  }

  .learn-more-classes {
    padding-top: 1.6rem;
  }

  .learn-more-classes p {
    font-size: 1em;
  }

  .full-schedule h1 {
    padding: 1rem;
    padding-top: 2.7rem;
    font-size: 2em;
    font-weight: bolder;
  }

  .dropdown button.dropdown-item {
    font-size: 0.7em;
  }

  .learn-more-classes h2 {
    font-size: 1.2rem;
    color: white;
  }

  .learn-more-classes .btn-group-vertical {
    width: 75%;
    max-width: 300px;
    margin: auto;
  }

  .learn-more-classes .btn-group-vertical .btn {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 0.7rem;
  }

  .desktop-page-title {
    display: none;
  }
  .classes-container {
    height: 100%;
    width: 100vw;
  }

  .left-listings {
    display: none;
  }

  .mobile-class-dropdown {
    width: 100%;
  }

  .dropdown {
    width: 100%;
    padding: 1rem;
    font-family: "Michroma", serif;
    text-align: center;
  }

  .dropdown button {
    width: 100%;
    font-size: 1.1em;
    letter-spacing: 0.2em;
    text-align: center;
    padding-top: 0.99rem;
    padding-bottom: 0.99rem;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: 900;
  }

  .btn.show {
    background-color: transparent;
    border-color: white;
  }

  .dropdown .btn:hover {
    background-color: white;
    border-color: white;
  }

  button.dropdown-item {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    padding-right: 0;
    padding-left: 0;
    color: black;
    font-size: 0.9em;
  }
  .dropdown button.dropdown-item {
    font-weight: normal;
  }

  .right-info {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
    padding-top: 0;
  }

  .class-desc-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2.3em;
    padding-top: 1.7em;
  }

  #cur-class-ages {
    font-size: 1.6em;
    margin-bottom: 0;
  }

  .page-title {
    order: 1;
    padding-top: 160px;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    text-align: center;
  }

  .page-title h1 {
    font-size: 2.1em;
    font-weight: 900;
    color: white;
  }

  .page-title p {
    font-size: 1.2em;
  }

  .class-image {
    order: 2;
  }

  .class-image img {
    width: 100%;
    height: auto;
    padding: 1.1rem;
    padding-top: 1.2rem;
  }

  .class-info {
    order: 3;
    text-align: center;
  }

  .class-schedule-button {
    order: 4;
  }

  .class-descriptions-button {
    order: 5;
  }

  #cur-class-sched1,
  #cur-class-sched3 {
    font-weight: 900;
    font-family: "Michroma", serif;
    font-size: 1.15rem;
    margin: 0;
    color: white;
  }

  #cur-class-sched2,
  #cur-class-sched4 {
    font-size: 1.2rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  #cur-class-rgstr {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.5);
  }

  #cur-class-description {
    font-size: 1.1em;
    color: white;
  }

  .class-schedule-button .btn {
    font-size: 01.5em;
    margin-top: 1.1rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 0.4rem;
    padding-left: 1.1rem;
    letter-spacing: 0.3rem;
  }

  .class-descriptions-button .btn {
    margin-top: 0rem;
    font-size: 1.5em;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 0.4rem;
    padding-left: 1.1rem;
    letter-spacing: 0.3rem;
  }
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .full-schedule img {
    width: 100%;
    max-width: 725px;
  }

  .learn-more-classes {
    padding-top: 2rem;
  }

  .learn-more-classes p {
    font-size: 1.1em;
  }

  .full-schedule h1 {
    padding: 1rem;
    padding-top: 3.5rem;
    font-size: 2.4em;
    font-weight: bolder;
  }

  .dropdown button.dropdown-item {
    font-size: 0.8em;
  }

  .learn-more-classes h2 {
    font-size: 1.2rem;
    color: white;
  }

  .learn-more-classes .btn-group-vertical {
    width: 75%;
    max-width: 300px;
    margin: auto;
  }

  .learn-more-classes .btn-group-vertical .btn {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 0.8rem;
  }

  .desktop-page-title {
    display: none;
  }
  .classes-container {
    height: 100%;
    width: 100vw;
  }

  .left-listings {
    display: none;
  }

  .mobile-class-dropdown {
    width: 100%;
  }

  .dropdown {
    width: 100%;
    padding: 1rem;
    font-family: "Michroma", serif;
    text-align: center;
  }

  .dropdown button {
    width: 100%;
    font-size: 1.1em;
    letter-spacing: 0.2em;
    text-align: center;
    padding-top: 0.99rem;
    padding-bottom: 0.99rem;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: 900;
  }

  .btn.show {
    background-color: transparent;
    border-color: white;
  }

  .dropdown .btn:hover {
    background-color: white;
    border-color: white;
  }

  button.dropdown-item {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    padding-right: 0;
    padding-left: 0;
    color: black;
    font-size: 0.9em;
  }
  .dropdown button.dropdown-item {
    font-weight: normal;
  }

  .right-info {
    margin: auto;
    width: 80%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
    padding-top: 0;
  }

  .class-desc-box {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2.3em;
    padding-top: 1.7em;
  }

  #cur-class-ages {
    font-size: 1.6em;
    margin-bottom: 0;
  }

  .page-title {
    order: 1;
    padding-top: 160px;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    text-align: center;
  }

  .page-title h1 {
    font-size: 2.1em;
    font-weight: 900;
    color: white;
  }

  .page-title p {
    font-size: 1.2em;
  }

  .class-image {
    order: 2;
    text-align: center;
  }

  .class-image img {
    width: 100%;
    height: auto;
    padding: 1.1rem;
    padding-top: 1.2rem;
    max-height: 650px;
    max-width: 650px;
    width: auto;
    margin: auto;
  }

  .class-info {
    order: 3;
    text-align: center;
  }

  .class-schedule-button {
    order: 4;
  }

  .class-descriptions-button {
    order: 5;
  }

  #cur-class-sched1,
  #cur-class-sched3 {
    font-weight: 900;
    font-family: "Michroma", serif;
    font-size: 1.15rem;
    margin: 0;
    color: white;
  }

  #cur-class-sched2,
  #cur-class-sched4 {
    font-size: 1.2rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  #cur-class-rgstr {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.5);
  }

  #cur-class-description {
    font-size: 1.1em;
    color: white;
  }

  .class-schedule-button .btn {
    font-size: 01.3em;
    margin-top: 1.1rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 0.4rem;
    padding-left: 1.1rem;
    letter-spacing: 0.3rem;
  }

  .class-descriptions-button .btn {
    margin-top: 0rem;
    font-size: 1.3em;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 0.4rem;
    padding-left: 1.1rem;
    letter-spacing: 0.3rem;
  }
}

@media only screen and (min-width: 1201px) {
  .class-desc-box {
    width: 75%;
    margin: auto;
  }
  .mobile-class-dropdown {
    max-width: 70%;
    margin: auto;
  }

  .full-schedule img {
    width: 100%;
    max-width: 725px;
  }

  .learn-more-classes {
    padding-top: 2.7rem;
    max-width: 75%;
    margin: auto;
  }

  .learn-more-classes p {
    font-size: 1.2em;
  }

  .full-schedule h1 {
    padding: 1rem;
    padding-top: 3.5rem;
    font-size: 2.4em;
    font-weight: bolder;
  }

  .dropdown button.dropdown-item {
    font-size: 0.8em;
  }

  .learn-more-classes h2 {
    font-size: 1.4rem;
    color: white;
  }

  .learn-more-classes .btn-group-vertical {
    width: 75%;
    max-width: 300px;
    margin: auto;
  }

  .learn-more-classes .btn-group-vertical .btn {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    font-size: 0.8rem;
  }

  .desktop-page-title {
    display: none;
  }
  .classes-container {
    height: 100%;
    width: 100vw;
  }

  .left-listings {
    display: none;
  }

  .mobile-class-dropdown {
    width: 100%;
  }

  .dropdown {
    width: 100%;
    padding: 1rem;
    font-family: "Michroma", serif;
    text-align: center;
  }

  .dropdown button {
    width: 100%;
    font-size: 1.1em;
    letter-spacing: 0.2em;
    text-align: center;
    padding-top: 0.99rem;
    padding-bottom: 0.99rem;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
    border-color: white;
    color: white;
    font-weight: 900;
  }

  .btn.show {
    background-color: transparent;
    border-color: white;
  }

  .dropdown .btn:hover {
    background-color: white;
    border-color: white;
  }

  button.dropdown-item {
    padding-top: 0.35em;
    padding-bottom: 0.35em;
    padding-right: 0;
    padding-left: 0;
    color: black;
    font-size: 0.9em;
  }
  .dropdown button.dropdown-item {
    font-weight: normal;
  }

  .right-info {
    margin: auto;
    width: 80%;
    display: flex;
    flex-flow: column;
    padding: 1rem;
    padding-top: 0;
  }

  .class-desc-box {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2.3em;
    padding-top: 1.7em;
  }

  #cur-class-ages {
    font-size: 1.6em;
    margin-bottom: 0;
  }

  .page-title {
    order: 1;
    padding-top: 160px;
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    text-align: center;
  }

  .page-title h1 {
    font-size: 2.1em;
    font-weight: 900;
    color: white;
  }

  .page-title p {
    font-size: 1.2em;
  }

  .class-image {
    order: 2;
    text-align: center;
  }

  .class-image img {
    width: 100%;
    height: auto;
    padding: 1.1rem;
    padding-top: 1.2rem;
    max-height: 650px;
    max-width: 650px;
    width: auto;
    margin: auto;
  }

  .class-info {
    order: 3;
    text-align: center;
  }

  .class-schedule-button {
    order: 4;
  }

  .class-descriptions-button {
    order: 5;
  }

  #cur-class-sched1,
  #cur-class-sched3 {
    font-weight: 900;
    font-family: "Michroma", serif;
    font-size: 1.15rem;
    margin: 0;
    color: white;
  }

  #cur-class-sched2,
  #cur-class-sched4 {
    font-size: 1.2rem;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  #cur-class-rgstr {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.5);
  }

  #cur-class-description {
    font-size: 1.1em;
    color: white;
  }

  .class-schedule-button .btn {
    font-size: 01.3em;
    margin-top: 1.1rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 0.4rem;
    padding-left: 1.1rem;
    letter-spacing: 0.3rem;
  }

  .class-descriptions-button .btn {
    margin-top: 0rem;
    font-size: 1.3em;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    padding-right: 0.4rem;
    padding-left: 1.1rem;
    letter-spacing: 0.3rem;
  }
}
