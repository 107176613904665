.home-container {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: -webkit-image-set(
    url("../assets/classesPage/cinderellaPrince-300.jpg") 1x,
    url("../assets/classesPage/cinderellaPrince-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/classesPage/cinderellaPrince-300.jpg") 1x,
    url("../assets/classesPage/cinderellaPrince-2000.jpg") 2x
  );
  background-image: image-set(
    url("../assets/classesPage/cinderellaPrince-300.jpg") 300w,
    url("../assets/classesPage/cinderellaPrince-768.jpg") 768w,
    url("../assets/classesPage/cinderellaPrince-1280.jpg") 1280w,
    url("../assets/classesPage/cinderellaPrince-2000.jpg") 2000w
  );
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}

.navbar-toggler {
  background-color: rgba(255, 255, 255, 0.5);
}

.description-event {
  width: 50%;
  background-color: rgb(0, 0, 0, 0.85);
  margin-right: 10em;
  margin-left: 10em;
  padding: 5rem;
  /* margin-top: 5rem; */
  margin-bottom: 2rem;
  max-width: 75%;
}

.description-event p {
  font-family: "Michroma", sans-serif;
  font-weight: 900;
}

#description-event-button .nyliabutton-container {
  width: 70%;
  height: auto;
  margin: 0 auto;
  border: 0.4mm solid;
  padding-right: 2rem;
  padding-left: 1.5rem;
  /* background-color: rgb(0, 0, 0, 01); */
}

#description-event-button .nyliabutton-container,
#description-event-button .nyliabutton-container:focus {
  /* background-color: rgb(0, 0, 0, 01); */
  color: transparent;
  box-shadow: transparent;
}

.description-event {
  width: auto;
  background-color: rgb(0, 0, 0, 0.75);
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 5rem;
  margin-bottom: 2rem;
  max-width: 85%;
}

.description {
  width: 50%;
  background-color: rgb(0, 0, 0, 0.5);
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 5rem;
  margin-bottom: 2rem;
  max-width: 85%;
}

.home-container .description p {
  font-family: "Questrial", sans-serif;
  font-weight: 100;
}

.description-event p {
  font-family: "Questrial", sans-serif;
  font-weight: 900;
}

.homeSocials {
  padding: 1.2rem;
}

.description h1 {
  font-family: "Michroma", sans-serif;
  font-weight: 900;
}

#NyliaMotto {
  font-family: "Questrial", sans-serif;
  font-weight: 100;
}

@media only screen and (max-width: 320px) {
  .home-container {
    padding-top: 150px;
  }

  .description,
  .description-event {
    padding: 3rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .description {
    width: 95%;
  }

  .description-event {
    width: 95%;
  }

  .description p,
  .description-event p {
    font-size: 0.7em;
  }

  .home-container .description-event h1 {
    font-size: 0.75rem;
  }
  .home-container .description h1 {
    font-size: 0.75rem;
  }

  .home-container .description h3 {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .description,
  .description-event {
    padding: 1.7rem;
  }

  .description p,
  .description-event p {
    font-size: 0.7em;
  }

  .description-event {
    margin-top: 10em;
  }

  .description {
    width: 95%;
  }

  .description-event {
    width: 92%;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .description {
    padding: 2.3rem;
  }

  .description-event {
    padding: 1.3rem;
  }

  .description p {
    font-size: 1.1em;
  }

  .description-event p {
    font-size: 1.1em;
  }

  .description-event {
    margin-top: 10em;
  }

  .description {
    width: 80%;
  }

  .description-event {
    width: 87%;
  }
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .description,
  .description-event {
    padding: 2.3rem;
  }

  .description p,
  .description-event p {
    font-size: 1.2em;
  }

  .description-event {
    margin-top: 10em;
  }

  .description {
    width: 70%;
  }

  .description-event {
    width: 87%;
  }
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) and (max-width: 888px) {
  .description,
  .description-event {
    padding: 1.3rem;
  }

  .description h1 {
    font-size: 1.5em;
  }

  .description p,
  .description-event p {
    font-size: 1.2em;
  }
  .description-event {
    margin-top: 10em;
  }
  .description {
    width: 70%;
  }

  .description-event {
    width: 87%;
  }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .description,
  .description-event {
    padding: 1.3rem;
  }

  .description p,
  .description-event p {
    font-size: 1.2em;
  }

  .description h1 {
    font-size: 1.5em;
  }

  .home-container {
    background-position: center;
  }

  .description-event {
    margin-top: 10em;
  }
  .description {
    width: 70%;
  }

  .description-event {
    width: 87%;
  }
}

/* Extra big screen devices (1201px and above) */
@media only screen and (min-width: 1201px) {
  .description,
  .description-event {
    padding: 1.6rem;
  }

  .description p,
  .description-event p {
    font-size: 1.3em;
  }

  .home-container {
    background-position: 25% 35%;
  }

  .description-event {
    /* width: auto; */
    margin-top: 10em;
  }
  .description h1 {
    font-size: 1.5em;
  }
  .description {
    width: 70%;
  }

  .description-event {
    width: 87%;
  }
}
