.companydancers-container {
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

#companydancers-logo {
  max-width: 250px;
  margin: 0;
  align-self: right;
}

.companydancers-container .nyliabutton-container .btn {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0;
}

.companydancers-container .nyliabutton-container .btn:hover {
  color: black;
}

.companydancers-container .card {
  background-color: rgb(1, 23, 23);
  align-items: center;
  color: white;
}

.companydancers-container .companydancers-main-box img {
  width: 100%!;
  height: auto;
}

@media all and (orientation: portrait) {
  .companydancers-container #main-img-bkg background-position {
    /* background-repeat: no-repeat;
    background-size: cover;
    position: relative; */
    -o-background-size: auto 100%;
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    background-size: auto 100%;
    background-position: right top;
    background-size: contain;
  }
}

@media all and (orientation: landscape) {
  .companydancers-container .main-img-bkg background-position {
    -o-background-size: 100% auto;
    -webkit-background-size: 100% auto;
    -moz-background-size: 100% auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    align-items: center;
  }
}

.companydancers-container .main-text {
  color: black;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  padding: 1.3rem;
  margin-bottom: 2rem;
  max-width: 85%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.companydancers-container .main-img {
  max-width: 500px;
  margin: auto;
}

.companydancers-container #auditions-nbc {
  background-image: -webkit-image-set(
    url("../../assets/nybc/nycb-liliani-300.jpg") 1x,
    url("../../assets/nybc/nycb-liliani-2000.jpg") 2x
  );
  background-image: image-set(
    url("../../assets/nybc/nycb-liliani-300.jpg") 1x,
    url("../../assets/nybc/nycb-liliani-2000.jpg") 2x
  );
  background-image: image-set(
    url("../../assets/nybc/nycb-liliani-300.jpg") 300w,
    url("../../assets/nybc/nycb-liliani-768.jpg") 768w,
    url("../../assets/nybc/nycb-liliani-1280.jpg") 1280w,
    url("../../assets/nybc/nycb-liliani-2000.jpg") 2000w
  );
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  background-position: center right;
}

.companydancers-container #mission-section {
  background-image: -webkit-image-set(
    url("../../assets/nbc/headshots/JPEG-300/nbcAuditions.jpg") 1x,
    url("../../assets/nbc/headshots/JPEG-2000/nbcAuditions.jpg") 2x
  );
  background-image: image-set(
    url("../../assets/nbc/headshots/JPEG-300/nbcAuditions.jpg") 1x,
    url("../../assets/nbc/headshots/JPEG-2000/nbcAuditions.jpg") 2x
  );
  background-image: image-set(
    url("../../assets/nbc/headshots/JPEG-300/nbcAuditions.jpg") 300w,
    url("../../assets/nbc/headshots/JPEG-768/nbcAuditions.jpg") 768w,
    url("../../assets/nbc/headshots/JPEG-1280/nbcAuditions.jpg") 1280w,
    url("../../assets/nbc/headshots/JPEG-2000/nbcAuditions.jpg") 2000w
  );
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;

  background-position: center;
}

.companydancers-container #nbc-section,
.companydancers-container #nybc-section {
  background-color: rgba(0, 0, 0, 0.95);
}

.companydancers-container #nbc-section .companydancers-main-box,
.companydancers-container #nybc-section .companydancers-main-box {
  background-color: transparent;
}

.companydancers-container .main-img-bkg-2 {
  background-image: -webkit-image-set(
    url("../../assets/nbc/headshots/JPEG-300/cinderellaandprince.jpg") 1x,
    url("../../assets/nbc/nbcClaraPurple-2000.jpg") 2x
  );
  background-image: image-set(
    url("../../assets/nbc/nbcClaraPurple-300.jpg") 1x,
    url("../../assets/nbc/nbcClaraPurple-2000.jpg") 2x
  );
  background-image: image-set(
    url("../../assets/nbc/nbcClaraPurple-300.jpg") 300w,
    url("../../assets/nbc/nbcClaraPurple-768.jpg") 768w,
    url("../../assets/nbc/nbcClaraPurple-1280.jpg") 1280w,
    url("../../assets/nbc/nbcClaraPurple-2000.jpg") 2000w
  );
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 3rem;

  background-position: center;
}

.companydancers-container #nybcSection {
  margin-top: 3rem;
}

.companydancers-container .main-title h1 {
  font-weight: 900;
  margin-bottom: 0.9em;
}

.companydancers-container h3 {
  font-family: "Questrial", sans-serif;
  text-transform: uppercase;
}

ul.main-bullets {
  list-style-type: circle;
}

.companydancers-container .companydancers-main-box p {
  text-align: center;
}

.companydancers-main-box {
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  /* margin-right: 2.5rem; */
  /* margin-left: 2.5rem; */
  padding: 1.3rem;
  margin-bottom: 2rem;
  /* max-width: 85%; */
}

.companydancers-container .companydancers-main-box.centered p {
  text-align: center;
}

.shadow-box p {
  text-align: left;
}

.shadow-box {
  width: 100%;
  background-color: rgb(0, 0, 0, 0.7);

  padding: 1.3rem;
  margin-bottom: 2rem;
  align-content: center;
}

.shadow-box.centered p {
  text-align: center;
}

.companydancers-container .nyliabutton-container .btn {
  font-size: 0.4rem;
}

.companydancers-container .main-title {
  color: white;
}

.companydancers-container .main-text p {
  font-size: 0.6em;
}

#mission-section .companydancers-main-box p {
  text-align: center;
}

@media only screen and (max-width: 320px) {
  .companydancers-container .main-title {
    margin-top: 9rem;
    font-size: 0.9em;
  }

  .companydancers-container .main-text p {
    font-size: 0.7em;
  }

  .companydancers-container .companydancers-main-box p {
    font-size: 0.7rem;
  }

  .companydancers-container .companydancers-main-box h2 {
    font-size: 0.6rem;
  }

  .companydancers-container h3 {
    font-size: 0.75rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.7rem;
  }

  .companydancers-container .nyliabutton-container .btn {
    font-size: 0.6rem;
  }

  .accordion #highlightTitle {
    font-size: 0.5rem;
  }
  #companydancers {
    max-width: 90%;
    margin: auto;
  }
  #companydancers .h5 {
    font-size: 0.65rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 480px) {
  .companydancers-container .main-title {
    margin-top: 8rem;
    font-size: 1em;
  }

  .companydancers-container .main-text p {
    font-size: 0.8em;
  }

  .companydancers-container .main-title h1 {
    font-size: 1.1rem;
  }

  .companydancers-container .companydancers-main-box p {
    font-size: 0.8rem;
  }

  .companydancers-container .companydancers-main-box h1 {
    font-size: 1.1rem;
  }
  .companydancers-container .companydancers-main-box h2 {
    font-size: 0.7rem;
  }

  .companydancers-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.75rem;
  }

  .companydancers-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }

  .accordion #highlightTitle {
    font-size: 0.7rem;
  }
  #companydancers {
    max-width: 80%;
    margin: auto;
  }
  #companydancers .h5 {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {
  .companydancers-container .main-title {
    margin-top: 10rem;
    font-size: 1.1em;
  }

  .companydancers-container .main-text p {
    font-size: 0.9em;
  }

  .companydancers-container .main-title h1 {
    font-size: 1.1rem;
  }

  .companydancers-container .companydancers-main-box p {
    font-size: 0.8rem;
  }

  .companydancers-container .companydancers-main-box h1 {
    font-size: 1.1rem;
  }
  .companydancers-container .companydancers-main-box h2 {
    font-size: 0.8rem;
  }

  .companydancers-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  .companydancers-container .main-title img {
    max-width: 90%;
  }

  li {
    font-size: 0.75rem;
  }

  .companydancers-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }
  .companydancers-container .companydancers-main-box {
    max-width: 85%;
    margin: auto;
  }
  #companydancers {
    max-width: 65%;
    margin: auto;
  }
  #companydancers .h5 {
    font-size: 0.78rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .companydancers-container .main-title {
    margin-top: 11rem;
    font-size: 1.2em;
  }

  .companydancers-container .main-text p {
    font-size: 1em;
  }

  .companydancers-container .main-title h1 {
    font-size: 1.1rem;
  }

  .companydancers-container .companydancers-main-box p {
    font-size: 0.9rem;
  }

  .companydancers-container .companydancers-main-box h1 {
    font-size: 1.1rem;
  }

  .companydancers-container .companydancers-main-box h2 {
    font-size: 0.9rem;
  }

  .companydancers-container h3 {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.8rem;
  }

  .companydancers-container .nyliabutton-container .btn {
    font-size: 0.8rem;
  }

  .companydancers-container .nyliabutton-container .btn {
    font-size: 0.7rem;
  }
  .companydancers-container .companydancers-main-box {
    max-width: 85%;
    margin: auto;
  }

  #companydancers {
    max-width: 75%;
    margin: auto;
  }
  #companydancers .h5 {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 888px) {
  .companydancers-container .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .companydancers-container .main-text p {
    font-size: 1.1em;
  }

  .companydancers-container .main-title h1 {
    font-size: 1.6rem;
  }

  .companydancers-container .companydancers-main-box p {
    font-size: 0.9rem;
  }

  .companydancers-container .companydancers-main-box h1 {
    font-size: 1.2rem;
  }

  .companydancers-container .companydancers-main-box h2 {
    font-size: 0.9rem;
  }

  .companydancers-container h3 {
    font-size: 1rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.9rem;
  }

  .companydancers-container .nyliabutton-container .btn {
    font-size: 0.9rem;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }

  .companydancers-container .companydancers-main-box {
    max-width: 85%;
    margin: auto;
  }

  #companydancers {
    max-width: 65%;
    margin: auto;
  }
  #companydancers .h5 {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 889px) and (max-width: 1200px) {
  .companydancers-container .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .companydancers-container .main-text p {
    font-size: 1em;
  }

  .companydancers-container .main-title h1 {
    font-size: 1.6rem;
  }

  .companydancers-container .companydancers-main-box p {
    font-size: 0.95rem;
  }

  .companydancers-container .companydancers-main-box h1 {
    font-size: 1.2rem;
  }
  .companydancers-container .companydancers-main-box h2 {
    font-size: 0.9rem;
  }
  .companydancers-container h3 {
    font-size: 1rem;
    font-weight: bolder;
  }

  li {
    font-size: 0.9rem;
  }

  .companydancers-container .nyliabutton-container .btn {
    font-size: 0.9rem;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }

  .companydancers-container .companydancers-main-box {
    max-width: 85%;
    margin: auto;
  }

  #companydancers {
    max-width: 65%;
    margin: auto;
  }
  #companydancers .h5 {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 1201px) {
  .companydancers-container .main-title {
    margin-top: 11.5rem;
    font-size: 1.2em;
  }
  .companydancers-container .main-text p {
    font-size: 1em;
  }

  .companydancers-container .main-title h1 {
    font-size: 2.2rem;
    font-weight: 900;
    margin-bottom: 0.9em;
  }

  .companydancers-container .companydancers-main-box p {
    font-size: 1.1rem;
  }

  .companydancers-container .companydancers-main-box h1 {
    font-size: 1.5rem;
  }
  .companydancers-container .companydancers-main-box h2 {
    font-size: 1.1rem;
  }

  .companydancers-container h3 {
    font-size: 1.3rem;
    font-weight: bolder;
  }

  li {
    font-size: 1rem;
  }

  .companydancers-container .nyliabutton-container .btn {
    font-size: 1rem;
  }

  #highlightTitle {
    font-size: 1.3rem;
  }

  .accordion #highlightTitle {
    font-size: 0.8rem;
  }

  .companydancers-container .companydancers-main-box {
    max-width: 85%;
    margin: auto;
  }
  #companydancers {
    max-width: 55%;
    margin: auto;
  }
  #companydancers .h5 {
    font-size: 0.95rem;
  }
}
